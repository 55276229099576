import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

// Antd
import {
  Badge,
  Button,
  Card,
  ConfigProvider,
  DatePicker,
  Dropdown,
  Empty,
  Input,
  Segmented,
  Space,
  Table,
} from "antd";

// Internal
import { UserType, useUrlParams } from "@/hooks/useUrlParams";
import {
  AttendanceStatus,
  AttendanceStatusTitles,
  IAttendance,
} from "@/types/attendance-sheet.types";
import { parseEndDateQueryParam } from "@/utils/dates";
import { MIN_DATE_THRESHOLD } from "@/api/attendance";
import { getAttendanceTableColumns } from "./attendance-table.columns";
import { getActiveCertificatesColumns } from "./active-certificates.columns";
import { ChildCertificate, FamilyCertificate } from "@/api/__types/attendance";
import { FamilyCertificatesLegend } from "@/components/ChildActiveCertificates";

// Icons
import { Filter, Search } from "@carbon/icons-react";

// MISC
import dayjs from "dayjs";

enum FamilyView {
  ATTENDANCE_SHEETS = "attendance-sheets",
  ACTIVE_CERTIFICATES = "active-certificates",
}

export default function EasTable({
  attendanceSheets,
  familyCertificates,
  loading,
}: {
  attendanceSheets: IAttendance[];
  familyCertificates: FamilyCertificate[];
  loading: boolean;
}) {
  // Search params
  const [searchParams, setSearchParams] = useSearchParams();
  const endQueryParam = searchParams.get("end") || "";
  const searchQuery = searchParams.get("search") || "";
  const familyViewQueryParam =
    searchParams.get("familyView") || FamilyView.ATTENDANCE_SHEETS;

  const { isFamilyView, userType } = useUrlParams({
    searchParams,
  });

  const numberOfActiveCertificates = useMemo(() => {
    return familyCertificates?.reduce(
      (accum, currentValue) => accum + currentValue.certificates.length,
      0
    );
  }, [familyCertificates]);

  // Status
  const statusQueryParam = searchParams
    .get("status")
    ?.split(",")
    .filter(Boolean);

  const selectedStatuses = statusQueryParam
    ? statusQueryParam
    : isFamilyView
      ? [AttendanceStatus.PROVIDER_SUBMITTED, AttendanceStatus.FAMILY_DENIED]
      : [AttendanceStatus.INCOMPLETE, AttendanceStatus.FAMILY_DENIED];

  const dataQuery = searchParams.get("data");

  const attendanceSheetColumns = getAttendanceTableColumns({
    dataQuery,
    endQueryParam,
    isFamilyView,
    userType,
  });
  const activeCertificatesColumns = getActiveCertificatesColumns();

  const _numberOfRecords = Array.isArray(attendanceSheets)
    ? attendanceSheets.length
    : 0;

  const attendanceSheetsTableData = useMemo(() => {
    return (attendanceSheets || []).filter((datum) => {
      if (!selectedStatuses.includes(datum.status)) {
        return false;
      }

      const _childName = datum?.child?.name?.toLowerCase();
      const _childId = datum?.child?.id?.toString();

      if (userType === UserType.PROVIDER) {
        const _householdId = datum?.child?.householdId?.toString();
        return (
          _childName.includes(searchQuery.toLowerCase()) ||
          _childId.includes(searchQuery) ||
          _householdId.includes(searchQuery)
        );
      } else {
        const _providerId = datum?.provider?.id?.toString();
        return (
          _childName.includes(searchQuery.toLowerCase()) ||
          _childId.includes(searchQuery) ||
          _providerId.includes(searchQuery)
        );
      }
    });
  }, [attendanceSheets, searchQuery, statusQueryParam, userType]);

  const filteredFamilyCertificates = useMemo(() => {
    if (userType === UserType.PROVIDER) {
      return [];
    }

    return (familyCertificates || []).map(
      ({ certificates, ...familyCertificateRest }) => {
        return {
          ...familyCertificateRest,
          certificates:
            searchQuery && searchQuery !== ""
              ? certificates.filter(
                  (_certificate) =>
                    _certificate.providerName
                      ?.toLowerCase?.()
                      ?.includes(searchQuery.toLowerCase()) ||
                    _certificate.providerId.toString().includes(searchQuery) ||
                    _certificate.certificateId.toString().includes(searchQuery)
                )
              : certificates,
        };
      }
    );
  }, [
    attendanceSheets,
    familyCertificates,
    searchQuery,
    statusQueryParam,
    userType,
  ]);

  const _hasRecordsOutsideOfFilteredState =
    attendanceSheetsTableData?.length !== _numberOfRecords;

  return (
    <>
      {userType === UserType.FAMILY && (
        <Segmented
          options={[
            {
              label: "Attendance Sheets",
              value: FamilyView.ATTENDANCE_SHEETS,
            },
            {
              label: "Active Certificates",
              value: FamilyView.ACTIVE_CERTIFICATES,
            },
          ]}
          value={familyViewQueryParam || FamilyView.ATTENDANCE_SHEETS}
          size="large"
          className="mb-6 border border-solid border-gray-300"
          onChange={(value) => {
            setSearchParams({
              ...Object.fromEntries(searchParams.entries()),
              familyView: value,
              // clear search query
              search: "",
            });
          }}
        />
      )}

      <Card
        title={
          loading
            ? undefined
            : familyViewQueryParam === FamilyView.ATTENDANCE_SHEETS
              ? `${attendanceSheetsTableData.length}${
                  _hasRecordsOutsideOfFilteredState
                    ? ` of ${_numberOfRecords}`
                    : ""
                } Attendance Sheet${_numberOfRecords !== 1 ? "s" : ""}`
              : `${numberOfActiveCertificates} Active Certificate${
                  numberOfActiveCertificates !== 1 ? "s" : ""
                }`
        }
        extra={
          <Space className="flex-wrap">
            <DatePicker
              allowClear={false}
              picker="month"
              format="MMM YYYY"
              disabledDate={(current) => {
                // Disable dates before the MIN_DATE_THRESHOLD
                if (current.isBefore(dayjs(MIN_DATE_THRESHOLD))) {
                  return true;
                }

                if (current.isSame(dayjs(), "month")) {
                  // Disable the current month if it's before the 25th
                  return dayjs().date() < 25;
                }

                return current > dayjs().endOf("month");
              }}
              onChange={(_date) => {
                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  end: _date.format("YYYY-MM"),
                });
              }}
              value={parseEndDateQueryParam(endQueryParam)}
              style={{
                width: "109px",
              }}
            />

            {familyViewQueryParam !== FamilyView.ACTIVE_CERTIFICATES && (
              <Dropdown
                menu={{
                  items: Object.keys(
                    AttendanceStatusTitles[userType] || {}
                  ).map((_status) => {
                    return {
                      label:
                        AttendanceStatusTitles[userType][
                          _status as AttendanceStatus
                        ] || _status,
                      key: _status,
                    };
                  }),
                  selectable: true,
                  selectedKeys: selectedStatuses,
                  multiple: true,
                  onSelect: ({ selectedKeys }) => {
                    setSearchParams({
                      ...Object.fromEntries(searchParams.entries()),
                      status: selectedKeys.join(","),
                    });
                  },
                  onDeselect: ({ selectedKeys }) => {
                    setSearchParams({
                      ...Object.fromEntries(searchParams.entries()),
                      status: selectedKeys.join(","),
                    });
                  },
                }}
                trigger={["click"]}
              >
                <Button icon={<Filter />}>
                  Filter by Status{" "}
                  <Badge
                    color="blue"
                    count={selectedStatuses.length}
                    className="ml-2"
                  />
                </Button>
              </Dropdown>
            )}

            <Input
              allowClear
              placeholder="Search by name or ID"
              prefix={<Search />}
              onChange={(e) => {
                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  search: e.target.value,
                });
              }}
              value={searchQuery}
            />
          </Space>
        }
        styles={{
          body: {
            padding: "0px",
          },
        }}
      >
        {familyViewQueryParam === FamilyView.ATTENDANCE_SHEETS && (
          <ConfigProvider
            renderEmpty={() => (
              <div style={{ textAlign: "center" }}>
                <Empty
                  className="my-12"
                  description={
                    _hasRecordsOutsideOfFilteredState
                      ? "There is data outside of your current filters"
                      : "No data"
                  }
                >
                  {_hasRecordsOutsideOfFilteredState && (
                    <Button
                      className="m-auto"
                      danger
                      onClick={() => {
                        setSearchParams({
                          ...Object.fromEntries(searchParams.entries()),
                          status: Object.keys(
                            AttendanceStatusTitles[userType] || {}
                          ).join(","),
                          search: "",
                        });
                      }}
                    >
                      Clear filters
                    </Button>
                  )}
                </Empty>
              </div>
            )}
          >
            <Table
              columns={attendanceSheetColumns}
              dataSource={attendanceSheetsTableData}
              pagination={{
                hideOnSinglePage: true,
                style: { marginRight: "24px" },
              }}
              rowKey="id"
              scroll={{ x: "max-content" }}
            />
          </ConfigProvider>
        )}
      </Card>

      {!loading && familyViewQueryParam === FamilyView.ACTIVE_CERTIFICATES && (
        <div className="mt-6 flex flex-col gap-6">
          {filteredFamilyCertificates?.map(
            ({ childId, childName, certificates }) => {
              return (
                <Card
                  key={childId}
                  title={
                    <>
                      {childName} (ID: {childId})
                    </>
                  }
                  styles={{
                    body: {
                      padding: "0px",
                    },
                  }}
                >
                  <Table<ChildCertificate>
                    columns={activeCertificatesColumns}
                    dataSource={certificates}
                    pagination={{
                      hideOnSinglePage: true,
                      style: { marginRight: "24px" },
                    }}
                    rowKey="id"
                    rowClassName={(record) => {
                      if (record.expiringSoonCritical) {
                        return "status-critical";
                      }

                      return "";
                    }}
                    scroll={{ x: "max-content" }}
                  />
                </Card>
              );
            }
          )}

          <FamilyCertificatesLegend />
        </div>
      )}
    </>
  );
}

import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "source-map-support/register";

// Internal
import App from "./App.tsx";
import ErrorBoundary from "./ErrorBoundary.tsx";

// Antd
import { ConfigProvider } from "antd";

// Styles
import "./styles/reset.css";
import "./index.css";

// MISC
import ReactGA from "react-ga";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Extend dayjs to add UTC utility functions
import dayjs from "dayjs";
import dayjsUtcPlugin from "dayjs/plugin/utc";
dayjs.extend(dayjsUtcPlugin);

if (import.meta.env.MODE === "production") {
  // Google Analytics
  ReactGA.initialize(
    (
      {
        dev: "G-REQDCVCJTW",
        staging: "G-G37X593ZN8",
        prod: "G-E2HKP2R608",
      } as any
    )[import.meta.env.VITE_SITE_ENV]
  );
}

// Create a client
const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ErrorBoundary>
          <ConfigProvider
            theme={{
              components: {
                Segmented: {
                  itemSelectedBg: "#1777ff",
                  itemSelectedColor: "#fff",
                },
              },
            }}
          >
            <App />
          </ConfigProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);

const siteEnv = import.meta.env.VITE_SITE_ENV;
if (typeof document !== "undefined") {
  if (siteEnv === "dev") {
    document.title = "🚧 EAS (Dev)";
  } else if (siteEnv === "staging") {
    document.title = "🚧 EAS (Staging)";
  }
}
